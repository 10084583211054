import Airwallex, {
  init as initFn,
  redirectToCheckout as redirectToCheckoutFn,
  createElement as createElementFn,
  destroyElement as destroyElementFn,
  getElement as getElementFn,
  confirmPaymentIntent as confirmPaymentIntentFn,
  confirmPaymentIntentWithSavedCard as confirmPaymentIntentWithSavedCardFn,
  createPaymentMethod as createPaymentMethodFn,
  getPaymentIntent as getPaymentIntentFn,
  createPaymentConsent as createPaymentConsentFn,
  AirwallexEnv,
} from '../types/airwallex';
import {
  getBrowserInfo as getBrowserInfoFn,
  getDeviceFingerprint as getDeviceFingerprintFn,
  get3dsReturnUrl as get3dsReturnUrlFn,
  handle3ds as handle3dsFn,
} from '../types/fraud';
import { loadAirwallex as loadAirwallexFn } from '../types/index';

const ENV_HOST = {
  prod: 'checkout.airwallex.com',
  demo: 'checkout-demo.airwallex.com',
  staging: 'checkout-staging.airwallex.com',
  /**
   * Below env only for the npm package development
   * Should not using them when integrate with Airwallex
   */
  qa: 'checkoutui.qa.awx.im',
  preview: 'checkoutui.preview.awx.im',
  dev: 'localhost:3000',
};

declare global {
  interface Window {
    Airwallex: Airwallex;
    ReactNativeWebView: {
      postMessage: (message: string) => void;
    };
  }
}

export const getGatewayUrl = (env: AirwallexEnv): string => `https://${ENV_HOST[env] || ENV_HOST.prod}`;

const STATIC_JS_URL = '/assets/elements.bundle.min.js';

export const loadAirwallexJs = (gatewayUrl: string): HTMLScriptElement => {
  const script = document.createElement('script');
  script.src = `${gatewayUrl}${STATIC_JS_URL}`;

  const headOrBody = document.head || document.body;

  if (!headOrBody) {
    throw new Error('Airwallex payment scripts requires a <head> or <body> html element in order to be loaded.');
  }

  headOrBody.appendChild(script);

  return script;
};

export const loadAirwallex: typeof loadAirwallexFn = async (options) => {
  if (typeof window === 'undefined') {
    return null;
  }

  if (window.Airwallex) {
    return window.Airwallex;
  }

  const MAX_RETRY_COUNT = 3;
  let RETRY_COUNT = 0;
  const sleep = () => new Promise((resolve) => window.setTimeout(resolve, 500));

  const tryToResolve = async (): Promise<Airwallex> => {
    const script: HTMLScriptElement =
      document.querySelector(`script[src="${STATIC_JS_URL}"], script[src="${STATIC_JS_URL}/"]`) ||
      loadAirwallexJs(getGatewayUrl(options?.env || 'prod'));

    return new Promise((resolve, reject) => {
      script.addEventListener('load', () => {
        if (window.Airwallex) {
          window.Airwallex.init(options);
          resolve(window.Airwallex);
        } else {
          reject(new Error('Failed to load Airwallex on load event'));
        }
      });

      script.addEventListener('error', () => {
        reject(new Error('Failed to load Airwallex scripts'));
        script.remove && script.remove();
      });
    });
  };

  while (RETRY_COUNT < MAX_RETRY_COUNT) {
    try {
      return await tryToResolve();
    } catch (error) {
      RETRY_COUNT++;
      await sleep();
    }
  }

  return null;
};

export const init: typeof initFn = (options) => {
  if (!window.Airwallex) {
    console.error('Please loadAirwallex() before init();');
  } else {
    window.Airwallex.init(options);
  }
};

export const redirectToCheckout: typeof redirectToCheckoutFn = (props) => {
  if (!window.Airwallex) {
    console.error('Please loadAirwallex() before redirectToCheckout();');
  } else {
    return window.Airwallex.redirectToCheckout(props);
  }
};

/**
 * @example
 * ```ts 
const element = Airwallex.createElement('dropIn', {
  intent_id: 'replace-with-your-intent-id',
  client_secret: 'replace-with-your-client-secret',
  currency: 'replace-with-your-intent-currency',
  // if you want to use apple pay, please pass merchant country code in applePayRequestOptions
  applePayRequestOptions: {
    countryCode: 'replace-with-your-country-code',
  },
  // if you want to use google pay, please pass merchant country code in googlePayRequestOptions
  googlePayRequestOptions: {
    countryCode: 'replace-with-your-country-code',
  },
});
```
 */

export const createElement: typeof createElementFn = (type, options) => {
  if (!window.Airwallex) {
    console.error('Please loadAirwallex() before createElement();');
    return null;
  } else {
    return window.Airwallex.createElement(type, options);
  }
};

export const destroyElement: typeof destroyElementFn = (type) => {
  if (!window.Airwallex) {
    console.error('Please loadAirwallex() before destroyElement();');
    return false;
  } else {
    return window.Airwallex.destroyElement(type);
  }
};

export const getElement: typeof getElementFn = (type) => {
  if (!window.Airwallex) {
    console.error('Please loadAirwallex() before getElement();');
    return null;
  } else {
    return window.Airwallex.getElement(type);
  }
};

export const confirmPaymentIntent: typeof confirmPaymentIntentFn = async (data) => {
  if (!window.Airwallex) {
    const err = 'Please loadAirwallex() before confirmPaymentIntent();';
    console.error(err);
    throw new Error(err);
  } else {
    return window.Airwallex.confirmPaymentIntent(data);
  }
};

export const confirmPaymentIntentWithSavedCard: typeof confirmPaymentIntentWithSavedCardFn = async (data) => {
  if (!window.Airwallex) {
    const err = 'Please loadAirwallex() before confirmPaymentIntentWithSavedCard();';
    console.error(err);
    throw new Error(err);
  } else {
    return window.Airwallex.confirmPaymentIntentWithSavedCard(data);
  }
};

export const createPaymentMethod: typeof createPaymentMethodFn = async (client_secret, data) => {
  if (!window.Airwallex) {
    const err = 'Please loadAirwallex() before createPaymentMethod();';
    console.error(err);
    throw new Error(err);
  } else {
    return window.Airwallex.createPaymentMethod(client_secret, data);
  }
};

export const getPaymentIntent: typeof getPaymentIntentFn = async (id, client_secret) => {
  if (!window.Airwallex) {
    const err = 'Please loadAirwallex() before getPaymentIntent();';
    console.error(err);
    throw new Error(err);
  } else {
    return window.Airwallex.getPaymentIntent(id, client_secret);
  }
};

export const createPaymentConsent: typeof createPaymentConsentFn = async (data) => {
  if (!window.Airwallex) {
    const err = 'Please loadAirwallex() before createPaymentConsent();';
    console.error(err);
    throw new Error(err);
  } else {
    return window.Airwallex.createPaymentConsent(data);
  }
};

export const getBrowserInfo: typeof getBrowserInfoFn = (data) => {
  return window.Airwallex.getBrowserInfo(data);
};

export const getDeviceFingerprint: typeof getDeviceFingerprintFn = (data) => {
  return window.Airwallex.getDeviceFingerprint(data);
};

// eslint-disable-next-line valid-jsdoc
/**
 * @deprecated this function would need exactly the same API version for merchant and element, so better to not use it
 */
export const get3dsReturnUrl: typeof get3dsReturnUrlFn = (data) => {
  return window.Airwallex.get3dsReturnUrl(data);
};

// eslint-disable-next-line valid-jsdoc
/**
 * @deprecated this function would need exactly the same API version for merchant and element, so better to not use it
 */
export const handle3ds: typeof handle3dsFn = (data) => {
  return window.Airwallex.handle3ds(data);
};
